import React from 'react'

function ToolBar() {
  return (
    <div>
    <div className="container-fluid bg-light pt-3 d-none d-lg-block">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center">
                        <p><i className="fa fa-envelope mr-2"></i>visitaus@outlook.com</p>
                        {/* <p className="text-body px-3">|</p> */}
                        {/* <p><i className="fa fa-phone-alt mr-2"></i>+61493779251</p> */}
                    </div>
                </div>
                <div className="col-lg-6 text-center text-lg-right">
                    <div className="d-inline-flex align-items-center">
                        <p><i className="fa fa-weixin mr-2"></i>Visitaustralia</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ToolBar