import React from "react";

const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const TourDetail = React.lazy(() => import('../pages/TourDetail'));
const Tours = React.lazy(() => import('../pages/Tours'));
const AttractionDetail = React.lazy(() => import('../pages/AttractionDetail'));
const Contact = React.lazy(() => import('../pages/Contact'));




export const router = [
    {
        path:"/",
        element: Home
    },
    {
        path:"/about",
        element: About
    },
    {
        path:"/tours",
        element: Tours
    },
    {
        path:"/tours/:id",
        element: TourDetail
    },
    {
        path:"/tours/:id/attraction/:name",
        element: AttractionDetail
    },
    {
        path:"/contact",
        element: Contact
    }

]