export const tours = [
    {
        "_id": 1,
        "name": "SYDENY🔥",
        "nameCh": "悉尼🔥",
        "imagePath": "/img/sydeny.jpg",
        "descriptionEn": "For a holiday focused on the great outdoors, head to the Blue Mountains, where you can see stunning rock formations, wander around quaint villages or explore magnificent wilderness. The Blue Mountains are famous for their sandstone ridges, steep and winding, covered with virgin forest. The wilderness is home to charming mountain towns, ancient Aboriginal rock art and an iconic tourist attraction: Scenic World. Entering the circular trail, facing verdant valleys and rugged cliffs, you will be fascinated by the natural beauty in front of you. After nightfall, go on a surprise firefly spotting tour in an underground cave or indulge in luxury at a wilderness resort. Whether it's the Blue Mountains shrouded in clouds, or the Blue Mountains glowing with golden light at sunset, there are endless views that will take your breath away.",
        "descriptionCh": "如果想要体验以户外活动为主的假期，你可以前往蓝山，在那里观赏震撼的岩层，在古雅的村庄四处闲逛，或探访壮丽的荒野。 蓝山拥有砂岩山脊，陡峭蜿蜒，上覆原始森林，闻名遐迩。荒野之中坐落着迷人的山城，遍布古老的原住民岩画艺术作品，还有标志性的旅游景点：风景世界（Scenic World）。进入环形步道，面对青翠山谷和崎岖悬崖，你一定会被眼前的自然美景深深地迷住。等夜幕降临后，到地下洞穴中来场惊喜连连的萤火虫发现之旅，或在荒野度假村内享受奢华体验。不论是云层笼罩下的蓝山，还是日落时分泛起金色光芒的蓝山，都有着能让你叹为观止的无尽美景。",
        "attractions": [
            {
            "_id": 1,
            "name": "Blue Mountains",
            "nameCh": "蓝山",
            "imagePath": "/img/blue_mountains/highfood.jpg",
            "descriptionEn": "The Blue Mountains is an Australian highlight, a vast region stretching out 11,400 kilometres of mountain tops, sandstone cliffs, and lush forest filled valleys. Folk will come across thundering waterfalls, epic lookouts, and even an array of unique wildlife when trekking through the land. Regardless of your choice in adventure, the Blue Mountains is truly the perfect place for an action-packed adventure amidst the Australian natural beauty.",
            "descriptionCh": "蓝山是澳大利亚的一大亮点，广阔的区域绵延 11,400 公里，山顶、砂岩悬崖和茂密的森林山谷。徒步穿越这片土地时，人们会遇到雷鸣般的瀑布、壮观的瞭望台，甚至还有一系列独特的野生动物。无论您选择哪种冒险方式，蓝山都是在澳大利亚自然美景中进行动感十足的冒险的理想场所。",
            "more": [
                {
                "_id": 1,
                "name": "Three Sisters",
                "nameCh": "三姐妹峰",
                "imagePath": "/img/blue_mountains/threeSister.jpg",
                "descriptionEn": "The Blue Mountains is an Australian highlight, a vast region stretching out 11,400 kilometres of mountain tops, sandstone cliffs, and lush forest filled valleys. Folk will come across thundering waterfalls, epic lookouts, and even an array of unique wildlife when trekking through the land. Regardless of your choice in adventure, the Blue Mountains is truly the perfect place for an action-packed adventure amidst the Australian natural beauty.",
                "descriptionCh": "蓝山是澳大利亚的一大亮点，广阔的区域绵延 11,400 公里，山顶、砂岩悬崖和茂密的森林山谷。徒步穿越这片土地时，人们会遇到雷鸣般的瀑布、壮观的瞭望台，甚至还有一系列独特的野生动物。无论您选择哪种冒险方式，蓝山都是在澳大利亚自然美景中进行动感十足的冒险的理想场所。",
            },
            {
                "_id": 2,
                "name": "Explore the oldest cave in the world",
                "nameCh": "探索世界上最古老的洞穴",
                "imagePath": "/img/blue_mountains/cave.jpg",
                "descriptionEn": "As the oldest cave in the world, the scenery of Jenolan Caves will surely take your breath away. Walking into the depths of the cave, passing stalactites hanging like icicles and such a clear and transparent lake, you will feel the coolness from the ground and wonder if there is really water in the lake.",
                "descriptionCh": "作为世界上最古老的洞穴，珍罗兰山洞的景色一定会让你叹为观止。走进洞穴深处，经过像冰柱一样悬挂的钟乳石和如此清澈透明的湖泊时，你会感受到来自地底的凉意，好奇湖里是否真的有水。",
            },
            {
                "_id": 3,
                "name": "Explore the oldest cave in the world",
                "nameCh": "One&Only 唯逸度假酒店",
                "imagePath": "/img/blue_mountains/one&only.jpg",
                "descriptionEn": "Emirates One&Only Wolgan Valley is set on a vast wilderness reserve. While staying at this luxurious resort, you can relax and enjoy signature experiences, from horseback riding and wildlife viewing tours to conservation hands-on activities.",
                "descriptionCh": "沃根谷阿联酋 One&Only 唯逸度假酒店（Emirates One&Only Wolgan Valley）坐落在广阔的荒野保护区上。入住这个豪华的度假村，你可以放松身心，享受标志性的服务体验，从骑马、野生动物观赏之旅到保护实践活动，应有尽有。 ",
            },
            {
                "_id": 4,
                "name": "Sky Picnic",
                "nameCh": "高空野餐",
                "imagePath": "/img/blue_mountains/highfood.jpg",
                "descriptionEn": "Scenic World is an excellent base for enjoying the extreme beauty of the mountains. The Beyond Skyway Experience even lets you climb out of the hatch and onto the roof of the gondola for a \"picnic in the sky\" overlooking the Jamison Valley. This is a memory that will last a lifetime.",
                "descriptionCh": "\"风景世界\"是欣赏山区极致美景的绝佳根据地。Beyond Skyway 体验之旅甚至可以让你爬出舱门，登上缆车车顶，一边俯瞰贾米逊谷（Jamison Valley），一边“高空野餐”。这是将让你铭记一生的回忆。",
            },
        ]
        },
        {
            "_id": 2,
            "name": "Byron Bay",
            "nameCh": "拜伦湾",
            "imagePath": "/img/byron_bay/wal.jpg",
            "descriptionEn": "This NSW town is a seaside paradise of coveted sun, sand and tranquility. Over the past few decades, Byron Bay has become famous for its alternative culture, surfers and maverick hippies. Today, it may be a little more refined, but the beauty has not faded in the slightest. You'll still find heart-pounding surf spots and laid-back yoga retreats that pay homage to Byron's hippie past. You'll also find up-and-coming restaurants, luxury beach houses and craft breweries with rave reviews and fame. From barefoot backpackers to Hollywood stars, Byron Bay attracts countless tourists.",
            "descriptionCh": "这个新南威尔士州小镇拥有令人向往的阳光、沙滩和宁静，是一个海滨天堂。过去几十年，拜伦湾以其另类文化、冲浪爱好者和特立独行的嬉皮士而闻名。如今，它或许变得更精致了点，但美景丝毫不曾褪色。你仍然会发现令人心潮起伏的冲浪点和悠静的瑜伽度假村，向拜伦（Byron）的嬉皮历史致敬。你也会发现新涌现的餐厅、豪华海滨别墅和精酿啤酒厂，好评如潮，声名远播。从赤脚背包客到好莱坞明星，拜伦湾吸引无数游客慕名而来。",
            "more": [
                {
                "_id": 1,
                "name": "Lighthouse Walk",
                "nameCh": "步行前往灯塔",
                "imagePath": "/img/byron_bay/lighthouse.jpg",
                "descriptionEn": "The iconic lighthouse of Byron Bay stands at the easternmost point of the Australian mainland, standing proudly by the sea, majestic. One of the best vantage points to see the lighthouse is the Cape Byron Lighthouse Track. The trail is also a great place to watch the sunrise and sunset.",
                "descriptionCh": "拜伦湾的标志性灯塔矗立在澳大利亚大陆最东端，傲立海边，气势雄伟。要观赏灯塔，最佳角度之一是拜伦角灯塔步道（Cape Byron Lighthouse Track）。该步道也是观看日出和日落的绝佳之地。",
            },
            {
                "_id": 2,
                "name": "Enjoy a luxurious stay",
                "nameCh": "享受豪华住宿",
                "imagePath": "/img/byron_bay/lux.jpg",
                "descriptionEn": "To take your Byron holiday to the next level why not book a beach house or luxury resort? There are so many amazing places to stay here, each with something unique. You can experience the open-air swimming pool, elegant spa and spacious guest rooms.",
                "descriptionCh": "想要将拜伦度假之旅提升到一个新的高度，何不预订海滨别墅或豪华度假村？这里有许多令人惊叹的住宿地点，每个都有独特之处。你可以体验露天泳池、优雅的水疗中心和宽敞的客房。",
            },
            {
                "_id": 3,
                "name": "Explore the outback",
                "nameCh": "探索内陆",
                "imagePath": "/img/byron_bay/out.jpg",
                "descriptionEn": "Just a short drive inland from Byron, you'll discover the hidden beauty of the hinterland. Quirky small towns, unique local restaurants and lush rainforests allow you to inhale fresh air and sample local cuisine every day.",
                "descriptionCh": "从拜伦向内陆驱车，只需一小段路，你就会发现腹地的秘境之美。古怪的小镇、独特的地方餐厅和茂密的雨林，让你每天都吸入新鲜空气，品尝当地美食。",
            },
            {
                "_id": 4,
                "name": "Whale watching",
                "nameCh": "观鲸",
                "imagePath": "/img/byron_bay/wal.jpg",
                "descriptionEn": "Scenic World is an excellent base for enjoying the extreme beauty of the mountains. The Beyond Skyway Experience even lets you climb out of the hatch and onto the roof of the gondola for a \"picnic in the sky\" overlooking the Jamison Valley. This is a memory that will last a lifetime.",
                "descriptionCh": "每年 5 月到 11 月，都会有迁徙的鲸鱼经过拜伦湾海岸。你可以从岸边一睹它们的身姿，也可以跳上赏鲸游船，近距离观赏它们用尾巴猛烈拍水的壮观场面。",
            },
        ]
        },
        {
            "_id": 3,
            "name": "Port Stephens",
            "nameCh": "史蒂芬斯港",
            "imagePath": "/img/steve/coa.jpg",
            "descriptionEn": "Explore the amazing beaches of Port Stephens and meet the wild dolphins that inhabit it. You can watch dolphins on a cruise, try parasailing, and fly above the sparkling blue waters. There are 26 sandy beaches, some hidden in coves, perfect for family outings as well as swimming, snorkeling and kayaking. Experience the thrill of racing through the towering Stockton sand dunes on quad bikes and horseback.",
            "descriptionCh": "探索史蒂芬斯港 (Port Stephens) 的奇妙海滩，与栖息于此的野生海豚不期而遇。您可以乘游船观赏海豚，尝试海上滑翔伞，在波光粼粼的蔚蓝海面上空飞掠而过。这里有 26 片沙滩，有些沙滩隐藏在海湾里，非常适合全家出游以及游泳、浮潜和划皮艇。您可驾驶四轮摩托车和骑马，驰骋于高耸的斯托克顿沙丘 (Stockton sand dunes) 之上，体验追风的快感。",
            "more": [
                {
                "_id": 1,
                "name": "Anna Bay",
                "nameCh": "在安娜湾上另辟蹊径",
                "imagePath": "/img/steve/ana.jpg",
                "descriptionEn": "Whether it's a slow camel ride along Stockton Beach or a horseback ride through Anna Bay, both offer visitors a better appreciation of the windswept beaches around Port Stephens and the dunes, all bathed in rose-gold glamor at dusk.",
                "descriptionCh": "无论是沿斯托克顿海滩（Stockton Beach）体验骆驼背上的慢旅行，还是骑马穿越安娜湾（Anna Bay），这两种方式可以都让游客更好地欣赏史蒂芬斯港周边大风驰骋的海滩和沙丘，黄昏时分，这一带全都笼罩在玫瑰金的迷人光彩下。",
            },
            {
                "_id": 2,
                "name": "Swim and play with wild dolphins",
                "nameCh": "与野生海豚共游嬉戏",
                "imagePath": "/img/steve/dop.jpg",
                "descriptionEn": "Dolphin Swim Australia runs Dolphin Swim tours in the waters off Port Stephens, where marine life is abundant.",
                "descriptionCh": "与海豚共游澳大利亚（Dolphin Swim Australia）在史蒂芬斯港附近水域经营了与海豚一起戏水游泳（Dolphin Swim）的旅游团项目，那里生活着大量海洋生物。",
            },
            {
                "_id": 3,
                "name": "Experience glamping at Koala Sanctuary",
                "nameCh": "在考拉保护区体验豪华露营",
                "imagePath": "/img/steve/coa.jpg",
                "descriptionEn": "A trip to the Port Stephens Koala Sanctuary offers visitors the chance to get involved in protecting local koalas. Gain insight into koala habits with the Sanctuary Story Walk experience, then camp overnight in a luxury tent.",
                "descriptionCh": "史蒂芬斯港考拉保护区（Port Stephens Koala Sanctuary）之行可为游客提供参与保护当地考拉的机会。跟随 Sanctuary Story Walk 体验，深入了解考拉习性，然后在豪华帐篷中露营过夜。",
            }
        ]
        },
        {
            "_id": 4,
            "name": "Hunter Valley",
            "nameCh": "猎人谷",
            "imagePath": "/img/hunter/lux.jpg",
            "descriptionEn": "Cruise the vineyards and sample local wines at cellar doors including Tyrrell’s, Brokenwood, Briar Ridge, De luliis, McGuigan and more. Embark on your journey from Sydney or travel with a group in the Hunter Valley. Savor sweet wines and local delicacies in boutique restaurants. Learn more about wine at the Hunter Valley Wine School. Treat yourself to the ultimate soothing spa experience at day spas like Château Élan. Play a round of golf at championship golf courses such as The Vintage Golf Course designed by Greg Norman, or at one of the many picturesque local golf courses. Take a stroll through the Hunter Valley Gardens and visit the children's favorite storybook gardens.",
            "descriptionCh": "巡游葡萄园，在各大酒窖品尝当地佳酿，包括 Tyrrell’s、Brokenwood、Briar Ridge、De luliis 和 McGuigan 等。从悉尼 (Sydney) 踏上旅程或在猎人谷 (Hunter Valley) 跟团旅行。在精品餐厅细细品味甘甜佳酿与当地美食。在猎人谷葡萄酒学校 (Hunter Valley Wine School) 深入了解葡萄酒。您可以在依兰城堡 (Château Élan) 等日间水疗中心，享受极致舒缓的水疗体验。在锦标赛级别的高尔夫球场上（如 Greg Norman 设计的 The Vintage 高尔夫球场），或者众多风景如画的当地高尔夫球场之一，畅快地打一场高尔夫。在猎人谷花园 (Hunter Valley Gardens) 闲庭信步，参观孩子们最喜欢的故事书花园。",
            "more": [
                {
                "_id": 1,
                "name": "Visit top local wineries",
                "nameCh": "拜访当地的顶级酒庄",
                "imagePath": "/img/hunter/wine.jpg",
                "descriptionEn": "The Hunter Valley is Australia's oldest varietal-growing region, so it's no wonder it knows how to make great wine. Take a tour of the cellar doors (there are 150 to choose from) and don't forget to sample their award-winning Shiraz and Semillon wines.",
                "descriptionCh": "猎人谷是澳大利亚历史最悠久的葡萄品种种植区，难怪它深谙美酒酿造之道。不妨走访各个酒窖（有 150 个酒窖可供选择），千万别忘了品尝他们备受赞誉的设拉子和赛美蓉葡萄酒。",
            },
            {
                "_id": 2,
                "name": "Watch the sunrise from the air",
                "nameCh": "从空中观看日出",
                "imagePath": "/img/hunter/sun.jpg",
                "descriptionEn": "Sunrise over the Hunter Valley horizon is stunning on a good day, but it's even more breathtaking when witnessed from the air. Breakfast accompanied by champagne and delicacies will make your morning unforgettable.",
                "descriptionCh": "在美好的时段，猎人谷地平线上的日出固然迷人，但如果能从空中见证，则更加令人叹为观止。有香槟和美食相伴的早餐，将让你度过一个难忘的早晨。",
            },
            {
                "_id": 3,
                "name": "Dine in a luxury restaurant",
                "nameCh": "在豪华餐厅用餐",
                "imagePath": "/img/hunter/lux.jpg",
                "descriptionEn": "There's no shortage of food, drink and fun in the Hunter Valley. You can enjoy gourmet platters, fresh farm-to-table cuisine in the picturesque vineyards, and taste exquisite desserts in the delicious dessert bar. Add to that the local wines, which are world famous, and it's no exaggeration to say that this is a gourmet paradise.",
                "descriptionCh": "猎人谷从来不乏各种各样的吃喝玩乐体验。你可以在风景如画的葡萄园内享用美食拼盘、从农场到餐桌的新鲜美食，在美味的甜品吧品尝精致甜点。加上当地举世闻名的葡萄酒，说这里是一个美食天堂也不为过。",
            }
        ]
        },
        {
            "_id": 5,
            "name": "City center",
            "nameCh": "悉尼市区",
            "imagePath": "/img/sydcity/harb.jpg",
            "descriptionEn": "With its many iconic sights and beautiful beaches, Sydney is a place you will never forget. Iconic structures such as the Sydney Harbor Bridge and Opera House are a must-see, but the harbor city is constantly evolving, with new attractions popping up all the time. New rooftop bars, theater shows and designer shops abound. If you are tired from shopping in the morning, you can lie on the beach and have a good rest in the afternoon to restore your physical strength and refresh your mind. In addition, there are also a variety of attractions around Sydney, suitable for day trips and weekend trips.",
            "descriptionCh": "悉尼拥有众多的标志性景点和美丽的海滩，是一个令你永远难忘的地方。悉尼海港大桥和歌剧院（Opera House）等标志性建筑自是必到景点，但这座海港城市还在不断发展，新兴景点不断涌现。新的屋顶酒吧、剧院表演和设计师商店比比皆是。上午逛累了，下午则可以躺在沙滩上好好休息，以恢复体力，养足精神。此外，悉尼周边也有丰富多样的景点，适合一日游和周末出游。",
            "more": [
                {
                "_id": 1,
                "name": "Sydney Opera House Day Tour",
                "nameCh": "悉尼歌剧院一日游",
                "imagePath": "/img/sydcity/opera.jpg",
                "descriptionEn": "You can dine at world-class restaurants, catch a show or go behind the scenes and visit the backstage areas for rich history and interesting celebrity anecdotes. Find out more about the experiential ways to meet Australia's most famous cultural icons.",
                "descriptionCh": "你可以在世界一流的餐厅用餐，观看表演或者走进幕后，参观后台区域，了解丰富的历史和有趣的名人轶事。了解更多接触澳大利亚最著名文化偶像的体验方式。",
            },
            {
                "_id": 2,
                "name": "Bondi Beach",
                "nameCh": "邦迪海滩",
                "imagePath": "/img/sydcity/bondi.jpg",
                "descriptionEn": "Head to Bondi Beach for a swim or take a surf lesson with Let's Go Surfing. Then browse boutiques along Gould Street, including trendy fashion house Tuchuzy, and sit down to one of Sydney's most famous breakfasts, Bills' ricotta banana muffins with honeycomb butter. Digest a gluttonous meal along the cliff-top path, which winds its way 3 kilometers (1.9 miles) from Bondi to Bronte.",
                "descriptionCh": "前往邦迪海滩（Bondi Beach）游泳或选择 Let's Go Surfing的冲浪课。然后沿着 Gould 街浏览精品店，包括热门时装店 Tuchuzy，坐下来享用悉尼最著名的早餐之一，Bills 的意大利乳清干酪香蕉松饼和蜂巢黄油。沿着悬崖顶部的路径消化贪吃的美食大餐，从邦迪到布朗特（Bronte）蜿蜒曲折 3 公里（1.9 英里）路程。",
            },
            {
                "_id": 3,
                "name": "Sydney Harbour Bridge",
                "nameCh": "悉尼海港大桥",
                "imagePath": "/img/sydcity/harb.jpg",
                "descriptionEn": "Climb to the top of the Sydney Harbor Bridge and watch the sun rise over the horizon from 134 meters (440 feet) above sea level. After Sydney recovers from its daytime vibrancy, wander the cobbled lanes of nearby The Rocks to discover its convict and colonial past, dating back to the arrival of English settlers in the late 1700s. If you visit The Rocks Weekend Markets, you'll find original artwork and handmade jewellery. Continue your stroll to Circular Quay and visit the lively Museum of Contemporary Art.",
                "descriptionCh": "去攀登悉尼海港大桥（Sydney Harbour Bridge）的顶峰，在海平面以上 134 米（440 英尺）观看太阳在地平线升起。 悉尼恢复日间活力之后，逛逛岩石区（The Rocks）附近鹅卵石铺成的胡同，探索罪犯和殖民主义时期的历史，可以追溯到 1700 年代末英国定居者的到来。如果您光顾岩石区周末市场（Rocks Markets），会发现那里陈列着原始艺术品和手工制成的珠宝首饰。继续漫步来到环形码头（Circular Quay），参观生动的当代艺术博物馆（Museum of Contemporary Art）。",
            },
            {
                "_id": 4,
                "name": "Taronga Zoo Sydney",
                "nameCh": "在悉尼塔龙加动物园（Taronga Zoo Sydney）与动物亲密接触",
                "imagePath": "/img/sydcity/targ.jpg",
                "descriptionEn": "Giraffes at Sydney's Taronga Zoo (Taronga Zoo Sydney) have the best view in the city. Take the ferry from Circular Quay to see and encounter more than 4,000 species of native and exotic animals. Why not spend the night here and see what else you can do at the zoo?",
                "descriptionCh": "悉尼塔龙加动物园（Taronga Zoo Sydney）的长颈鹿拥有全市最佳视野。从环形码头（Circular Quay）乘坐渡轮，亲眼目睹和邂逅 4,000 多种本土和外来动物。何不在此过夜，并看看你还能在动物园玩些什么？",
            },
            {
                "_id": 5,
                "name": "Visit museums and galleries",
                "nameCh": "参观博物馆和美术馆",
                "imagePath": "/img/sydcity/mus.jpg",
                "descriptionEn": "Sydney's museums and galleries tell compelling stories through classic and contemporary art, Aboriginal artefacts and scientific specimens. Whether you're visiting a major museum or a neighborhood gallery, there's something special to discover.",
                "descriptionCh": "悉尼的博物馆和美术馆通过古典及当代艺术作品、原住民文物和科学标本，讲述引人入胜的故事。无论你是参观大型博物馆还是街区画廊，都能发现一些特别的东西。",
            }
        ]
        },
    ],
    },
    {
        "_id": 2,
        "name": "Melbourne🔥",
        "nameCh": "墨尔本🔥",
        "imagePath": "/img/melbourne.jpg",
        "descriptionEn": "Melbourne is the coastal capital of the southeastern Australian state of Victoria. At the city's centre is the modern Federation Square development, with plazas, bars, and restaurants by the Yarra River. In the Southbank area, the Melbourne Arts Precinct is the site of Arts Centre Melbourne – a performing arts complex – and the National Gallery of Victoria, with Australian and indigenous art.",
        "descriptionCh": "墨尔本是澳大利亚东南部维多利亚州的沿海首府。位于市中心的是现代化的联邦广场开发项目，亚拉河畔设有广场、酒吧和餐厅。在南岸地区，墨尔本艺术区是墨尔本艺术中心（一个表演艺术中心）和维多利亚国家美术馆（展示澳大利亚和土著艺术）的所在地。",
        "attractions": [
            {
            "_id": 1,
            "name": "Great Ocean Road",
            "nameCh": "大洋路",
            "imagePath": "/img/bigo/bigo.jpg",
            "descriptionEn": "The Blue Mountains is an Australian highlight, a vast region stretching out 11,400 kilometres of mountain tops, sandstone cliffs, and lush forest filled valleys. Folk will come across thundering waterfalls, epic lookouts, and even an array of unique wildlife when trekking through the land. Regardless of your choice in adventure, the Blue Mountains is truly the perfect place for an action-packed adventure amidst the Australian natural beauty.",
            "descriptionCh": "蓝山是澳大利亚的一大亮点，广阔的区域绵延 11,400 公里，山顶、砂岩悬崖和茂密的森林山谷。徒步穿越这片土地时，人们会遇到雷鸣般的瀑布、壮观的瞭望台，甚至还有一系列独特的野生动物。无论您选择哪种冒险方式，蓝山都是在澳大利亚自然美景中进行动感十足的冒险的理想场所。",
            "more": [
                {
                "_id": 1,
                "name": "Spectacle of wildlife",
                "nameCh": "欣赏令人惊叹的野生动物奇观",
                "imagePath": "/img/bigo/ani.jpg",
                "descriptionEn": "Take Otways' wildlife tour 'Wildlife Wonders' and walk through alsophila gorge and eucalypt woodland with spectacular sea views. Take a walk through the Australian bush on a route devised by the art director of The Hobbit film.",
                "descriptionCh": "你可以参加奥特威（Otways）的野生动物之旅“野生动物奇观”（Wildlife Wonders），徒步穿越桫椤涧谷和桉树林地，欣赏壮观的海景。漫步在《霍比特人》电影艺术总监设计的路线中，了解澳大利亚丛林。",
            },
            {
                "_id": 2,
                "name": "adventure in the treetops",
                "nameCh": "在树顶冒险",
                "imagePath": "/img/bigo/tree.jpg",
                "descriptionEn": "Take Otways' wildlife tour 'Wildlife Wonders' and walk through alsophila gorge and eucalypt woodland with spectacular sea views. Take a walk through the Australian bush on a route devised by the art director of The Hobbit film.",
                "descriptionCh": "在奥特威树顶探险（Otway Fly Treetop Adventures）体验中，全家人可以参加各种惊险刺激的冒险活动。在这里，你可以套上高空滑索在树梢间滑行，沿着树顶高空步道漫步，或者游览充满精灵和小仙女的奇幻世界。",
            },
            {
                "_id": 3,
                "name": "The Twelve Apostles",
                "nameCh": "十二门徒岩",
                "imagePath": "/img/bigo/12.jpg",
                "descriptionEn": "These towering stone pillars are the highlights of the (Great Ocean Road) on the Great Ocean Road, formed by the erosion of limestone between 10 million and 20 million years ago. You can stop at Teddy's Lookout (Teddy's Lookout), along the rugged cliffs and admire the sea stacks rising from the sea.",
                "descriptionCh": "这些高耸的石柱是大洋路（Great Ocean Road）上的亮点，是由 1 千万年至 2 千万年前的石灰岩侵蚀而成的。你可以在泰迪观景点（Teddy’s Lookout）停留片刻，沿着崎岖的悬崖，欣赏一下从海中凸起的海蚀柱。",
            },
        ]
        },
        {
            "_id": 2,
            "name": "Phillip Island",
            "nameCh": "菲利普岛",
            "imagePath": "/img/fei/fei.jpg",
            "descriptionEn": "Phillip Island is a fascinating destination just 90 minutes drive from Melbourne. There are plenty of attractions to attract nature lovers, including the famous penguin homing.",
            "descriptionCh": "菲利普岛是一个令人神往的目的地，距离墨尔本仅90 分钟车程。那里有众多景点吸引着自然爱好者，包括著名的企鹅归巢。",
            "more": [
                {
                "_id": 1,
                "name": "Explore history and Aboriginal culture",
                "nameCh": "探索历史和原住民文化",
                "imagePath": "/img/fei/cul.jpg",
                "descriptionEn": "Take Otways' wildlife tour 'Wildlife Wonders' and walk through alsophila gorge and eucalypt woodland with spectacular sea views. Take a walk through the Australian bush on a route devised by the art director of The Hobbit film.",
                "descriptionCh": "你可以参观菲利普岛历史社会博物馆（Historical Society Museum）展览，了解澳大利亚人的航海历史、原住民文化以及澳大利亚早期定居者的生活情况。或者，驱车前往丘吉尔岛传统农场（Churchill Island Heritage Farm），参观那里的历史建筑和花园。",
            },
            {
                "_id": 2,
                "name": "Penguin homing",
                "nameCh": "企鹅归巢",
                "imagePath": "/img/fei/qie.jpg",
                "descriptionEn": "Take Otways' wildlife tour 'Wildlife Wonders' and walk through alsophila gorge and eucalypt woodland with spectacular sea views. Take a walk through the Australian bush on a route devised by the art director of The Hobbit film.",
                "descriptionCh": "企鹅归巢是菲利普岛的招牌景点之一，黄昏时，觅食一天的小企鹅们会返回巢穴，它们庞大的阵容和滑稽的走路姿势令人捧腹。如果想寻找一个好视角来观看企鹅归巢场景，可以去夏日海滩（Summerland Beach），在那里，你还可以参加 VIP 和有导游的旅行。",
            },
            {
                "_id": 3,
                "name": "Taste local delicacies",
                "nameCh": "品尝当地美食",
                "imagePath": "/img/fei/food.jpg",
                "descriptionEn": "These towering stone pillars are the highlights of the (Great Ocean Road) on the Great Ocean Road, formed by the erosion of limestone between 10 million and 20 million years ago. You can stop at Teddy's Lookout (Teddy's Lookout), along the rugged cliffs and admire the sea stacks rising from the sea.",
                "descriptionCh": "菲利普岛上有两个拥有酒窖的凉爽气候葡萄酒酒庄，一个是菲利普岛酒庄（Phillip Island Winery），该酒庄出产各种美味的红葡萄酒和白葡萄酒，酒庄里还有一家可爱的精品店，供应新鲜的当地特产；另一个是紫鸡酒庄（Purple Hen Wines），以黑皮诺（pinot noir）和设拉子（shiraz）而闻名。",
            },
        ]
        },
        {
            "_id": 3,
            "name": "Mornington Peninsula",
            "nameCh": "摩林顿半岛",
            "imagePath": "/img/mon/mon.jpg",
            "descriptionEn": "The Mornington Peninsula is a jewel on the Victorian coastline, not only rich in seaside style, but also famous for its fine wine. With golden beaches, hilly vineyards and spectacular wilderness, the Mornington Peninsula has a lot to offer. If you're feeling adventurous, try some memorable activities like swimming with dolphins or hiking along the coast. With world-class wineries (especially famous for producing chardonnay and pinot noir) and award-winning restaurants, foodies can enjoy delicious food and wine. This is also a paradise for you to relax. You can comfortably enjoy hot springs or the comfortable services of luxury hotels.",
            "descriptionCh": "摩林顿半岛是维多利亚海岸线上的一颗明珠，此地不但海滨风情浓郁，还是闻名遐迩的美酒之乡。在摩林顿半岛上，你可以看到金色的海滩、遍布山丘的葡萄园和壮观的荒野景象，这里有很多值得体验之处。如果你想要冒险，可以去尝试一些令人难忘的活动，譬如与海豚一起游泳或者沿海远足。这里有世界一流的酒庄（尤以出产霞多丽（chardonnay）和黑皮诺（pinot noir）而闻名）和备受好评的餐厅，吃货们可以尽享美酒佳肴。此处也是你放松身心的世外桃源。你可以惬意地享受泡温泉或豪华酒店的舒适服务。",
            "more": [
                {
                "_id": 1,
                "name": "Taste Australia's premier Pinot Noir",
                "nameCh": "品尝澳大利亚的一流黑皮诺葡萄酒",
                "imagePath": "/img/mon/wine.jpg",
                "descriptionEn": "The Mornington Peninsula has many winemaking stories and has produced many excellent cool climate wines such as Pinot Noir and Chardonnay. Besides the fine wines, the views of the vineyards themselves are just as breathtaking. Learn about the region's fine wines.",
                "descriptionCh": "摩林顿半岛流传着许多葡萄酒酿制的故事，生产出很多优质的凉爽气候葡萄酒，如黑皮诺和霞多丽。除了美酒，葡萄园本身的景致也同样令人心旷神怡。了解该地区的美酒佳酿。",
            },
            {
                "_id": 2,
                "name": "Enjoy the spa",
                "nameCh": "享受水疗",
                "imagePath": "/img/mon/water.jpg",
                "descriptionEn": "The Mornington Peninsula offers a wide variety of food and wine to please everyone. Peninsula Hot Springs (Peninsula Hot Springs) is deep and quiet, where you will feel refreshed and rejuvenated after experiencing natural hot spring mineral baths, soothing spa treatments and restorative treatments.",
                "descriptionCh": "摩林顿半岛可以供应各色美食美酒，让每位来宾都能大饱口福。半岛温泉（Peninsula Hot Springs）幽深静谧，在这里体验天然温泉矿物浴、舒畅的水疗护理以及恢复性疗养后，你会感到精神振奋，身心重新充满活力。",
            },
        ]
        },
        {
            "_id": 4,
            "name": "Melbourne City",
            "nameCh": "墨尔本市区",
            "imagePath": "/img/mel/mel.jpg",
            "descriptionEn": "Rich cultural history and new era trends are perfectly integrated in Melbourne. The night rises, the sunset is colorful, and the city is full of brilliance at night. People gather for dinner, participate in activities and watch exhibitions, and enjoy the joyful nightlife. You can stroll through the busy lanes, fashionable neighborhoods and exquisite food places, and enjoy the infinite charm of Melbourne.",
            "descriptionCh": "丰富的文化历史、新时代潮流，在墨尔本实现完美融合。夜幕升起，晚霞斑斓，城市夜晚流光溢彩，人们聚餐，参加活动和观赏展览，尽情享受欢乐的夜生活。你可以漫步于人来人往的巷道、时尚的街区和精致的美食场所，领略墨尔本的无穷魅力。",
            "more": [
                {
                "_id": 1,
                "name": "Downtown Alleyway Exploration",
                "nameCh": "市中心巷道探秘",
                "imagePath": "/img/mel/rai.jpg",
                "descriptionEn": "Some of Melbourne's laneways are as busy as a main street. Not so Union Lane. Walk down this quiet street and you'll find yourself in a whole different world, where every wall is covered in ever-changing street art. You might even bump into an artist creating a new work under your gaze. While these colorful creations are more vivid during the day, walking around at night is another fun experience, with the occasional light shining through the windows like a spotlight on a particular piece of art.",
                "descriptionCh": "墨尔本的有些巷道车水马龙，与主大街无异。联盟巷道（Union Lane）则不然。沿着这条安静的街道走下去，你会发现自己置身在一个完全不同的世界里，这里的每一面墙上都涂满了不断变化的街道艺术画。你甚至可能碰到一个艺术家在你的注视下创作一件新作品。虽然这些色彩缤纷的作品在白天更显生动，但夜间散步却是另外一种有趣的体验，偶尔从窗口透射出的灯光就像聚光灯一样聚焦在某件特定艺术品上。",
            },
            {
                "_id": 2,
                "name": "Taste the best coffee in Melbourne",
                "nameCh": "品尝墨尔本最好的咖啡",
                "imagePath": "/img/mel/coff.jpg",
                "descriptionEn": "Melbourne's coffee craze began after World War II, with the arrival of Italian and Greek immigrants, and evolved into an art form. The city's coffee entrepreneurs scour premium coffee-producing regions around the world in search of single-origin coffees to appeal to their sophisticated customers. There’s not only espresso and flat white (like a latte), but also pour over, siphon and iced drip.",
                "descriptionCh": "墨尔本的咖啡热始于二战后，随着意大利和希腊移民的到来而兴起，并逐渐演变为一种艺术形式。该市的咖啡企业家们踏遍世界各地的优质咖啡产区，寻找单品咖啡来吸引他们老道的顾客。这里不仅有浓缩咖啡和澳白（类似拿铁），还有采用手冲、虹吸和冰滴等方式制作的咖啡。",
            },
        ]
        },
    ],
        
    },
    {
        "_id": 3,
        "nameCh": "堪培拉",
        "name": "Canberra",
        "imagePath": "/img/canber.jpg",
        "descriptionEn": "Canberra is the capital city of Australia. Founded following the federation of the colonies of Australia as the seat of government for the new nation, it is Australia's largest inland city and the eighth-largest Australian city overall.",
        "descriptionCh": "堪培拉是澳大利亚的首都。继澳大利亚殖民地联邦成立后，作为新国家的政府所在地，它是澳大利亚最大的内陆城市，也是澳大利亚第八大城市。",
        "attractions": [{
            "_id": 1,
            "name": "Canberra City",
            "nameCh": "堪培拉市区之旅",
            "imagePath": "/img/can/can.jpg",
            "descriptionEn": "This small but beautiful city will bring you more surprises than what you see on the surface. Famous museums and historical sites abound. Dig deeper and you'll find lively beer bars, hidden niche spots, peaceful nature and family-friendly attractions. You can also get out of town and relax in the scenic cool-climate wine regions and savor their golden waves.",
            "descriptionCh": "这座小而美的城市，带给你的惊喜将远比你表面看到的还要多。著名的博物馆和历史遗迹比比皆是。深入探寻，你会发现热闹的啤酒吧、隐秘的小众打卡地、宁静的大自然和适合家庭游玩的景点。你还可以走出城外，在风景秀丽的凉爽气候葡萄酒产区放松身心，品味那里的玉液金波。",
            "more": [
                {
                "_id": 1,
                "name": "Visit the Australian Parliament Building",
                "nameCh": "参观国会大厦",
                "imagePath": "/img/can/yi.jpg",
                "descriptionEn": "The Blue Mountains is an Australian highlight, a vast region stretching out 11,400 kilometres of mountain tops, sandstone cliffs, and lush forest filled valleys. Folk will come across thundering waterfalls, epic lookouts, and even an array of unique wildlife when trekking through the land. Regardless of your choice in adventure, the Blue Mountains is truly the perfect place for an action-packed adventure amidst the Australian natural beauty.",
                "descriptionCh": "千万别错过在导游带领下探索国会大厦（Parliament House）的机会，你可以欣赏其现代建筑艺术，在其中的展览馆和美术馆中徜徉，或者提前一窥会议召开场面，了解澳大利亚的民主活动。",
            },{
                "_id": 1,
                "name": "Visit galleries and museums",
                "nameCh": "探访美术馆和博物馆",
                "imagePath": "/img/can/mei.jpg",
                "descriptionEn": "This small but beautiful city will bring you more surprises than what you see on the surface. Famous museums and historical sites abound. Dig deeper and you'll find lively beer bars, hidden niche spots, peaceful nature and family-friendly attractions. You can also get out of town and relax in the scenic cool-climate wine regions and savor their golden waves.",
                "descriptionCh": "探索原住民艺术作品，参观国际展览，漫步在遍布各种原创雕像的花园中，欣赏名人的肖像。在堪培拉的博物馆和美术馆中度过美好的下午时光，你一定会感叹不虚此行。",
            }
        ]
        },
    ],
    },
    {
        "_id": 4,
        "nameCh": "珀斯",
        "name": "Perth",
        "imagePath": "/img/perth.jpg",
        "descriptionEn": "Perth, capital of Western Australia, sits where the Swan River meets the southwest coast. Sandy beaches line its suburbs, and the huge, riverside Kings Park and Botanic Garden on Mount Eliza offer sweeping views of the city. The Perth Cultural Centre houses the state ballet and opera companies, and occupies its own central precinct, including a theatre, library and the Art Gallery of Western Australia.",
        "descriptionCh": "西澳大利亚首府珀斯位于天鹅河与西南海岸的交汇处。郊区遍布沙滩，伊丽莎山上巨大的河畔国王公园和植物园可将城市美景一览无余。珀斯文化中心设有国家芭蕾舞团和歌剧团，并占据自己的中心区域，包括剧院、图书馆和西澳大利亚美术馆。",
        "attractions": [
            {
            "_id": 1,
            "name": "Take a photo with quokkas on Rottnest Island",
            "nameCh": "在罗特尼斯岛与短尾矮袋鼠合影留念",
            "imagePath": "/img/per/luo.jpg",
            "descriptionEn": "Surrounded by turquoise waters and filled with lush vegetation, Rottnest Island invites a variety of outdoor adventures. In other words, it has all the makings of a perfect day trip. In addition to the classic project of taking a photo with a quokka, there are many must-see experiences waiting for you to explore on the island.",
            "descriptionCh": "罗特尼斯岛（Rottnest Island）被碧绿的海水环抱，到处是郁郁葱葱的植被，可以进行各种户外探险活动。换句话说，这里具备了完美一日游的所有条件。除了与短尾矮袋鼠（quokka）合影这一经典项目，岛上还有许多必游体验等你探索。",
            "more": []
            },            
            {
            "_id": 2,
            "name": "Stroll the old streets of Freemantle",
            "nameCh": "漫步弗里曼特尔的老街",
            "imagePath": "/img/per/fu.jpg",
            "descriptionEn": "The charming port town of Fremantle may be Perth's most historic neighbourhood, but amidst the heritage buildings you'll find amazing street art, buskers, breweries and al fresco cafes . Here are the freemantle day trips.",
            "descriptionCh": "迷人的港口小镇弗里曼特尔（Fremantle）可能是珀斯历史最悠久的街区，但在众多古建筑之间，你会发现令人惊叹的街头艺术、街头艺人、啤酒厂和露天咖啡馆。这里介绍了弗里曼特尔一日游活动。",
            "more": []
            },
            {
                "_id": 3,
                "name": "Humpback Whale Tour",
                "nameCh": "座头鲸之旅",
                "imagePath": "/img/per/zuo.jpg",
                "descriptionEn": "Every spring, more than 35,000 humpback whales migrate along the Western Australian coastline. Be amazed when you come face-to-face with these gentle giants in the waters of Hillarys Boat Harbor on a whale watching cruise.",
                "descriptionCh": "每年春天，有超过 35,000 头座头鲸沿着西澳大利亚州海岸线迁徙。当你乘坐赏鲸游轮在希拉里船港（Hillarys Boat Harbour）水域中与这些性情温和的巨兽面对面时，一定会惊叹不已。 ",
                "more": []
                },
    ],
    },
    {
        "_id": 5,
        "nameCh": "阿德莱德",
        "name": "Adelaide",
        "imagePath": "/img/adl.jpg",
        "descriptionEn": "Adelaide is South Australia’s cosmopolitan coastal capital. Its ring of parkland on the River Torrens is home to renowned museums such as the Art Gallery of South Australia, displaying expansive collections including noted Indigenous art, and the South Australian Museum, devoted to natural history. The city's Adelaide Festival is an annual international arts gathering with spin-offs including fringe and film events.",
        "descriptionCh": "阿德莱德是南澳大利亚的国际化沿海首府。托伦斯河畔的公园绿地环是著名博物馆的所在地，例如展示大量藏品的南澳大利亚美术馆，包括著名的土著艺术，以及致力于自然历史的南澳大利亚博物馆。该市的阿德莱德艺术节是一年一度的国际艺术盛会，其衍生活动包括边缘活动和电影活动。",
        "attractions": [
            {
            "_id": 1,
            "name": "Kangaroo Island",
            "nameCh": "袋鼠岛",
            "imagePath": "/img/dai/dai.jpg",
            "descriptionEn": "Kangaroo Island is a destination for nature lovers, with its abundance of native wildlife, stunning coastal scenery and endless farmland. Kangaroo Island, 13 kilometers off the coast of South Australia, is one of the best places in Australia to see wildlife such as koalas, kangaroos, sea lions and seals. Kangaroo Island also has breathtaking geological wonders and excellent local food and wine, which will definitely open your eyes.",
            "descriptionCh": "袋鼠岛是自然爱好者心驰神往的地方，这里有丰富的本土野生动物、壮丽的海滨风光和一望无垠的农田。袋鼠岛距离南澳大利亚州海岸 13 公里处，是在澳大利亚观赏野生动物（如考拉、袋鼠、海狮和海豹）的最佳地点之一。袋鼠岛上还拥有令人叹为观止的地质奇观和口味绝佳的本地美食美酒，定会让你大开眼界。",
            "more": [
                {
                "_id": 1,
                "name": "Appreciate the natural geological wonders",
                "nameCh": "欣赏自然地质奇观",
                "imagePath": "/img/dai/di.jpg",
                "descriptionEn": "Explore the island's stunning natural beauty, such as the aptly named Remarkable Rocks. Don't miss Admiral's Arch, an open cave leading to the ocean, where New Zealand fur seals play and a variety of beautiful beaches such as sparkling Stoke Stokes Bay.",
                "descriptionCh": "探索该岛令人惊叹的自然风光，例如，名副其实的神奇岩石（Remarkable Rocks）。千万别错过旗舰拱门（Admiral’s Arch），这是一个通向海洋的开放洞穴，新西兰海狗（New Zealand fur seals）在此嬉戏，还有各式美丽的海滩，例如，波光粼粼的司托克斯湾（Stokes Bay）。",
            },
            {
                "_id": 2,
                "name": "Walk among the sea lions",
                "nameCh": "漫步在海狮群中",
                "imagePath": "/img/dai/hai.jpg",
                "descriptionEn": "In Seal Bay (Seal Bay), you can walk among groups of Australian sea lions (Australian sea lions), watch female sea lions rest, cubs play and male sea lions fight. You can walk alone on the short boardwalk, or you can learn about these endangered animals with a guide.",
                "descriptionCh": "在海豹湾（Seal Bay），你可以在成群的澳大利亚海狮（Australian sea lion）中漫步，观看母海狮休息、幼崽玩耍以及雄海狮争斗的场面。你可以独自在短木栈道上漫步观赏，也可以在导游带领下，了解这些濒危动物。",
            },
            {
                "_id": 3,
                "name": "Food guide around the island",
                "nameCh": "环岛饮食攻略",
                "imagePath": "/img/dai/food.jpg",
                "descriptionEn": "With fertile farms and pristine oceans, this island is a paradise for foodies. Sample real Ligurian-bee honey, enjoy the Chatter Platter at Millie Mae's Pantry café, or sample local oysters and King George cod at the Oyster Farm Shop (King George whiting).",
                "descriptionCh": "这座岛屿有土壤肥沃的农场和原生态的海洋，是吃货们的天堂。你可以品尝纯正的利古里亚蜂蜜（Ligurian-bee honey），在 Millie Mae’s Pantry 咖啡馆享用“回味拼盘”（Chatter Platter），或者在生蚝养殖场工厂（Oyster Farm Shop）品尝当地生蚝和乔治王鳕鱼（King George whiting）。",
            },
        ]
        },
        {
            "_id": 2,
            "name": "Barossa valley",
            "nameCh": "芭萝莎谷",
            "imagePath": "/img/bar/bar.jpg",
            "descriptionEn": "Kangaroo Island is a destination for nature lovers, with its abundance of native wildlife, stunning coastal scenery and endless farmland. Kangaroo Island, 13 kilometers off the coast of South Australia, is one of the best places in Australia to see wildlife such as koalas, kangaroos, sea lions and seals. Kangaroo Island also has breathtaking geological wonders and excellent local food and wine, which will definitely open your eyes.",
            "descriptionCh": "袋鼠岛是自然爱好者心驰神往的地方，这里有丰富的本土野生动物、壮丽的海滨风光和一望无垠的农田。袋鼠岛距离南澳大利亚州海岸 13 公里处，是在澳大利亚观赏野生动物（如考拉、袋鼠、海狮和海豹）的最佳地点之一。袋鼠岛上还拥有令人叹为观止的地质奇观和口味绝佳的本地美食美酒，定会让你大开眼界。",
            "more": [
                {
                "_id": 1,
                "name": "Distill your own gin",
                "nameCh": "亲手酿制杜松子酒",
                "imagePath": "/img/bar/qin.jpg",
                "descriptionEn": "If you want to leave an exclusive memory in the Barossa, why not brew a bottle of gin yourself? At Maggie Beer’s Farm, you can master innovative techniques with native botanicals, learn about the distillation process, and even keep records of your recipes. Learn more here.",
                "descriptionCh": "想要在芭萝莎留下一段专属回忆，为何不亲手酿造一瓶杜松子酒呢？在麦姬 · 比尔农场（Maggie Beer’s Farm），你可以掌握有关本土植物的创新工艺，了解蒸馏过程，甚至记录保留你的配方。在此处了解更多信息。",
            },
            {
                "_id": 2,
                "name": "Experience a unique winery experience",
                "nameCh": "体会独特的酒庄体验",
                "imagePath": "/img/bar/ti.jpg",
                "descriptionEn": "The Barossa is famous for its wineries, but what makes it truly unique is the experience within the winery. From tasting vintages of the same age to picnicking in prized vineyards, these top winery experiences are well worth exploring.",
                "descriptionCh": "芭萝莎因其酒庄而闻名，但真正独特的地方在于酒庄内的体验。从品尝同龄的年份葡萄酒，到在珍贵的葡萄园内野餐，这些顶级酒庄体验都非常值得一探。",
            },
            {
                "_id": 3,
                "name": "Taste top wines",
                "nameCh": "品尝顶级佳酿",
                "imagePath": "/img/bar/pin.jpg",
                "descriptionEn": "No trip to the Barossa is complete without a visit to a few wine cellars. The Barossa is rich in bold flavors such as Shiraz and lively Grenache. The surroundings are just as intoxicating as the wine. Here are a few top wine cellars.",
                "descriptionCh": "来到芭萝莎，如果未能多多少少造访几个酒窖的话，那么这一行定是不完整的。芭萝莎盛产风味大胆的设拉子和口感鲜活的歌海娜等。周边环境也与葡萄酒一样醉人。此处列举了几座顶尖酒窖。",
            },
        ]
        },
        {
            "_id": 3,
            "name": "Eyre Peninsula",
            "nameCh": "艾尔半岛",
            "imagePath": "/img/aier/aier.jpg",
            "descriptionEn": "On the Eyre Peninsula, away from the hustle and bustle of the city, enjoy the pristine landscape in peace, watch abundant wild animals and enjoy delicious seafood. The Eyre Peninsula is Australia's main seafood distribution center. With more than 65 percent of Australia's seafood, including green-lipped abalone, scallops, prawns, oysters and tuna, coming from these waters, seafood restaurants abound in the area. In addition, it also has a very diverse and beautiful natural scenery, and many outdoor adventure activities are fascinating. For example, swim with dolphins and sea lions, go cage diving to see great white sharks, or join a whale watching group to get up close and personal with whales.",
            "descriptionCh": "在艾尔半岛（Eyre Peninsula）上，远离城市的喧嚣，清静地享受原始景观，观赏丰富的野生动物，享用美味的海鲜。艾尔半岛是澳大利亚主要的海鲜集散中心。澳大利亚 65% 以上的海鲜，包括绿唇鲍鱼（green-lipped abalone）、扇贝、虾、生蚝和金枪鱼等都来自这片水域，得此地利，该地区的海鲜餐馆比比皆是。除此之外，这里还拥有着非常多样化的美丽自然风光，众多的户外探险活动令人神往。例如，与海豚和海狮一起游泳，坐吊笼潜水观赏大白鲨，或者加入观鲸团，与鲸鱼近距离接触。",
            "more": [
                {
                "_id": 1,
                "name": "Explore coastal caves",
                "nameCh": "探索沿海洞穴",
                "imagePath": "/img/aier/dong.jpg",
                "descriptionEn": "Caves, tunnels, cliffs, crevasses and blowholes can be seen along the coastline. Visit Talia Caves, see craggy rock formations sculpted by the lapping waves, explore nearby beaches, or go deeper into the caldera.",
                "descriptionCh": "沿着海岸线一路可以看到洞穴、隧道、悬崖、裂缝和喷水孔。参观塔利亚洞穴（Talia Caves），欣赏经海浪拍打雕琢而成的崎岖岩层，探索附近的海滩，或者深入火山口一探究竟。",
            },
            {
                "_id": 2,
                "name": "Taste fresh seafood",
                "nameCh": "品尝新鲜海鲜",
                "imagePath": "/img/aier/seafood.jpg",
                "descriptionEn": "The Eyre Peninsula is famous for its seafood. You can join the Seafood Frontier tour, enjoy oysters and fresh fish directly salvaged from the sea, enjoy the ocean scenery, and experience an unparalleled sea experience. Learn about the itinerary for a gourmet road trip.",
                "descriptionCh": "艾尔半岛以盛产海鲜而闻名。你可以参加海鲜前哨之旅（Seafood Frontier），享用从海中直接打捞上来的生蚝（oyster）和鲜鱼，欣赏海洋风景，感受无与伦比的海上体验。了解美食自驾游的行程安排。",
            },
            {
                "_id": 3,
                "name": "Gondola diving to see great white sharks",
                "nameCh": "吊笼潜水观赏大白鲨",
                "imagePath": "/img/aier/shark.jpg",
                "descriptionEn": "Neptune Islands (Neptune Islands), located in the south of the Eyre Peninsula, is the only place in Australia where you can experience cage diving and watch great white sharks. Getting up close and personal with these incredible marine predators is an experience of a lifetime.",
                "descriptionCh": "海王星岛（Neptune Islands）位于艾尔半岛南边，是澳大利亚唯一可以体验吊笼潜水观赏大白鲨的地方。与这些令人难以置信的海洋掠食者近距离接触，带给你终身难忘的体验。",
            },
        ]
        },
    ],
    },
    {
        "_id": 6,
        "nameCh": "霍巴特",
        "name": "Hobart",
        "imagePath": "/img/horbart.jpg",
        "descriptionEn": "Hobart, capital of Australia's island state of Tasmania, sits on the River Derwent. At its fashionable Salamanca Place, old sandstone warehouses host galleries and cafes. Nearby is Battery Point, a historic district with narrow lanes and colonial-era cottages. The city's backdrop is 1,270m-high Mount Wellington, with sweeping views, plus hiking and cycling trails.",
        "descriptionCh": "霍巴特是澳大利亚塔斯马尼亚岛州的首府，坐落在德文特河畔。在其时尚的萨拉曼卡广场，古老的砂岩仓库设有画廊和咖啡馆。附近是 Battery Point，这是一个历史悠久的地区，拥有狭窄的小巷和殖民时期的小屋。这座城市的背景是 1,270 米高的惠灵顿山，享有一览无余的景色，还有远足和自行车道。",
        "attractions": [
            {
            "_id": 1,
            "name": "Meet the Tasmanian Devil up close",
            "nameCh": "与塔斯马尼亚恶魔近距离见面",
            "imagePath": "/img/hor/dev.jpg",
            "descriptionEn": "Tasmania is home to some of the most unique animals in the world. You can take the family to visit Bonorong Wildlife Sanctuary (Bonorong Wildlife Sanctuary) to see (Tasmanian devil) up close and learn about the uniqueness of this animal and other native species.",
            "descriptionCh": "塔斯马尼亚州是全世界一些最独特的动物的家园。你可以带着家人一同参观波诺朗野生动物保护区（Bonorong Wildlife Sanctuary），近距离欣赏塔斯马尼亚恶魔（Tasmanian devil），了解这种动物以及其他本土物种的独特之处。 ",
            "more": []
        },
        {
            "_id": 2,
            "name": "Climb to the top of Mount Wellington",
            "nameCh": "登上惠灵顿山山顶",
            "imagePath": "/img/hor/hui.jpg",
            "descriptionEn": "You can cycle, walk or drive to the summit of kunanyi/Mount Wellington for breathtaking views. The sun rises, the mist dissipates, and the cityscape, Bruny Island and the Tasman Peninsula slowly appear in front of your eyes. Go explore what the mountain has to offer.",
            "descriptionCh": "你可以骑单车，徒步或驾车，前往库纳尼/惠灵顿山（kunanyi/Mount Wellington）的顶峰，一睹令人屏息的美景。太阳升起，薄雾消散，城市风光、布鲁尼岛（Bruny Island）以及塔斯曼半岛（Tasman Peninsula）的风光慢慢呈现在你的眼前。去探索一番这座山都有哪些美景。",
            "more": []
        },
        {
            "_id": 3,
            "name": "Bruny Island Day Trip",
            "nameCh": "布鲁尼岛一日游",
            "imagePath": "/img/hor/bru.jpg",
            "descriptionEn": "Bruny Island is small in size, but it is unique and impressive in terms of landscape, entertainment and food. The beautiful natural scenery here will soothe your soul and nourish your body with fresh oysters and gourmet cheeses. Come and experience this special place.",
            "descriptionCh": "布鲁尼岛（Bruny Island）面积不大，但在景观、娱乐项目和美食方面独具特色，给人留下深刻印象。此地美丽的自然风光会荡涤你的心灵，新鲜生蚝和美味奶酪可以滋养你的身体。好好感受一下这个特别的地方吧。 ",
            "more": []
        },
        {
            "_id": 4,
            "name": "Take a walk on the Shake Shore Trail",
            "nameCh": "漫步摇圣徒步道",
            "imagePath": "/img/hor/yao.jpg",
            "descriptionEn": "The mountains with strange peaks, cold icy lakes, unique grasslands and virgin rainforests are all in Cradle Mountain-Lake St Clair National Park (Cradle Mountain-Lake St Clair National Park). In Cradle Mountain (Cradle Mountain), a Tasmanian wilderness area listed as a World Heritage Site, you can try world-class hiking trails, watch unique wildlife and embrace nature.",
            "descriptionCh": "奇峰罗列的山峦、寒冷的冰蚀湖、独特的草原和原始雨林，尽在摇篮山-圣克莱尔湖国家公园（Cradle Mountain-Lake St Clair National Park）。在列入世界遗产地的塔斯马尼亚州荒野地区 —— 摇篮山（Cradle Mountain），你可以尝试世界级的徒步游步道，观赏独特野生动物，拥抱大自然。",
            "more": []
        },
    ],
    },
    {
        "_id": 7,
        "nameCh": "达尔文",
        "name": "Darwin",
        "imagePath": "/img/darw.jpg",
        "descriptionEn": "The Gold Coast is a metropolitan region south of Brisbane on Australia’s east coast. It's famed for its long sandy beaches, surfing spots and elaborate system of inland canals and waterways. It’s also home to theme parks such as Dreamworld, Sea World and Wet’n’Wild. Inland, hiking trails crisscross Lamington National Park’s mountain ridges and valleys, home to rare birds and rainforest.",
        "descriptionCh": "达尔文是澳大利亚北领地的首府，也是前边境哨所。它也是通往广阔的卡卡杜国家公园的门户。其受欢迎的海滨区拥有数个海滩和绿地，如双世纪公园。靠近水边的还有北领地博物馆和艺术画廊，展示东南亚和太平洋艺术，还有一艘珍珠帆船和其他航海船只。",
        "attractions": [
            {
            "_id": 1,
            "name": "Crazy about crocodiles",
            "nameCh": "我为鳄狂",
            "imagePath": "/img/daer/daer.jpg",
            "descriptionEn": "You can see saltwater crocodiles up close (and safely) on a gator hop cruise, or get your heart pumping on a crocodile dive encounter. While keeping a safe distance, you can still feel the ferocity of the crocodiles as they jump, bite and roll. Choose your own crocodile adventure tour.",
            "descriptionCh": "你可以参加鳄鱼跳巡游之旅，近距离（安全地）观察咸水鳄，也可以潜水邂逅鳄鱼，体验心跳加速的感觉。虽然保持着安全距离，但当鳄鱼跳跃、撕咬和翻滚的时候，你仍能感受到它们的凶猛。选择你自己的鳄鱼探险之旅。 ",
            "more": []
        },
        {
            "_id": 2,
            "name": "explore national parks",
            "nameCh": "探索国家公园",
            "imagePath": "/img/daer/guo.jpg",
            "descriptionEn": "This tropical city is ideally located and surrounded by many natural wonders, perfect for excursions and adventures. Surrounded by World Heritage-listed national parks, islands, wetlands and waterfalls, the city offers spectacular vistas just a short journey from the city.",
            "descriptionCh": "这座热带城市地理位置优越，四周环绕着众多自然奇景，非常适合开启短途游和探险活动。该市周围有列入世界遗产名录的国家公园、岛屿、湿地和瀑布，从市区出发很快就能到达，一览壮丽景观。  ",
            "more": []
        },
        {
            "_id": 3,
            "name": "Catch the rare barramundi",
            "nameCh": "捕捞珍贵的澳大利亚肺鱼",
            "imagePath": "/img/daer/bar.jpg",
            "descriptionEn": "Barramundi Adventures Darwin is an onshore recreational fishery where you can fish and experience the thrill of catching the iconic barramundi of the North End, ideal for both the novice and advanced angler. Go fishing in Darwin.",
            "descriptionCh": "Barramundi Adventures Darwin 是一处陆上休闲渔场，你可以在这里垂钓，感受捕捉北端地区代表性的澳大利亚肺鱼的刺激体验，无论是新手还是垂钓高手，这里都是理想去处。在达尔文体验垂钓。 ",
            "more": []
        },

    ],
    },
    {
        "_id": 8,
        "nameCh": "乌鲁鲁🔥",
        "name": "Uluru🔥",
        "imagePath": "/img/uluru.jpg",
        "descriptionEn": "Formerly known as Ayers Rock, Uluru is made of sandstone about half a billion years old. It stands 348 metres high and has a circumference of 9.4 km. Uluru is at its most stunning around sunrise and sunset, when the golden light makes the rock’s colours come alive. For the Anangu people, Uluru is inseparable from Tjukurpa, or traditional law. The actions of the creation ancestors are still visible around the rock, and their stories are passed on from generation to generation, just as they have been for thousands of years. Uluru is a spectacular panorama, but it’s real beauty can be found by looking closer. This ancient monolith is home to rare plants and animals, important spiritual sites and caves painted with remarkable rock art.",
        "descriptionCh": "乌鲁鲁原名艾尔斯岩，由大约 50 亿年前的砂岩构成。它高348米，周长9.4公里。乌鲁鲁在日出和日落时分最为迷人，金色的光芒使岩石的颜色变得生动起来。对于 Anangu 人来说，乌鲁鲁与 Tjukurpa 或传统法律密不可分。创世先民的作为在岩石周围仍然清晰可见，他们的故事代代相传，一如千年。乌鲁鲁是一个壮观的全景，但只有仔细观察才能发现它真正的美丽。这块古老的巨石是珍稀动植物、重要的精神场所和绘有非凡岩石艺术的洞穴的家园。",
        "attractions": [{
            "_id": 1,
            "name": "Four-day family vacation in Uluru and surrounding areas",
            "nameCh": "乌鲁鲁及周边地区家庭度假四日游",
            "imagePath": "/img/wululu/ulu.jpg",
            "descriptionEn": "It's too far, too expensive, and children may not 'get' the cultural significance - reasons why many families have yet to visit Australia's iconic rock. It is because of the remoteness that it is different. The journey can be expensive, but once you're here, there are plenty of great free and low-cost family activities you can experience. There is a strong spiritual and cultural tradition here, which is just right for children, as they are perhaps the most receptive (and most moving) audience for any creation story. Have these reasons convinced you? Use this four-day itinerary to guide your travels.",
            "descriptionCh": "距离太远，花费太贵，孩子们可能无法“理解”文化意义 —— 正是因为这些原因，许多家庭至今未曾造访澳大利亚这块标志性岩石。正因为偏远，所以才与众不同。旅途费用也许昂贵，但一旦到达这里，你可以体验许多精彩丰富的免费或低价家庭活动。这里拥有深厚的精神文化传统，正适合孩子们，因为他们或许是最容易接受任何创世故事（也是最深受感动）的听众。这些理由说服你了吗？用这份四日由行程来指导自己的旅行。",
            "more": [
                {
                "_id": 1,
                "name": "ULURU-KATA TJUTA NATIONAL PARK",
                "nameCh": "第 1 天：前往乌鲁鲁卡塔丘塔国家公园",
                "imagePath": "/img/wululu/w1.jpg",
                "descriptionEn": "It's perfectly possible to drive all the way to Uluru, but you can also follow this itinerary and fly there. Most capital cities in Australia have direct flights to Yulara (a small town on the edge of Uluru-Kata Tjuta National Park), with flight times ranging from two to four hours. This flight journey will exceed your imagination, and the view of Uluru from above during the flight is a wonderful experience in itself - so worth it! After landing on the airstrip in this red land, you can rent a car or take the free airport bus to Ayers Rock Resort. You can rewatch the red rock monolith's beauty at sunset at one of the park's five viewpoints. You might as well bring a picnic (and a camera), find a suitable location on the dunes, and watch the colorful clouds reflect the entire Uluru, covering the desert with colorful feathers. Alternatively, you can relax on your first night and book a sunset tour with SEIT Outback Australia.",
                "descriptionCh": "全程驱车前往乌鲁鲁是完全可行的，不过你也可以遵照本次行程，飞行抵达。澳大利亚的大多数首府城市都有直抵尤拉拉（位于乌鲁鲁卡塔丘塔国家公园边上的一个小镇）的航班，飞行时间从两到四个小时不等。这一飞行之旅将超出你的想象，在飞行途中从高空俯瞰乌鲁鲁的美景，这本身就是一次美妙的体验 ——太值得了！在这片红土地上的飞机跑道上降落后，你可以租一辆车或乘坐免费机场巴士前往艾雅斯岩度假村。你可以在公园的五个观景点之一再次领略红岩巨石在日落时分的旖旎美景。不妨带上野餐（和相机），在沙丘上寻找一处合适的位置，看绚丽彩霞映照着整个乌鲁鲁，为沙漠披上浓墨重彩的羽衣霓裳。或者，你可以在第一晚放松休息，并预订 SEIT 澳大利亚内陆之旅（SEIT Outback Australia）的日落之旅。",
            },
            {
                "_id": 2,
                "name": "Explore Uluru",
                "nameCh": "第 2 天：探索乌鲁鲁",
                "imagePath": "/img/wululu/w2.jpg",
                "descriptionEn": "In the morning, you can visit the cultural center of Uluru-Kata Tjuta National Park to learn more about Uluru's significance to the traditional Aboriginal Anangu people. It focuses on Tjukurpa (Aboriginal law, moral system and culture) and the natural and human history of the park through exhibitions, exhibits and lectures. Entry to the center is free with a National Park Ticket - required for all visitors to enter Uluru-Kata Tjuta National Park and can be booked in advance. It's just a five-minute drive from here to the Mala car park, which is the meeting point for the free ranger-led Mala Walk. The 2km track is one of two short, family-friendly walks. Another walk is the Kuniya Walk to Mutitjulu Waterhole (Kuniya Walk to Mutitjulu Waterhole). The trail revolves around a part of the Uluru boulder, passing through the ancient cave where the ancestors of the Anangu people, the Mara people (Mara means reddish-brown rabbit kangaroo), first lived in, then passed through a traditional rock art area, and finally arrived at Canter Canyon (Kantju Gorge). For a more relaxed approach to travel, don a hard hat, hop on a Segway or rent a bike and ride the 11 kilometers of bike paths.",
                "descriptionCh": "早上你可以去参观乌鲁鲁卡塔丘塔国家公园的文化中心，进一步了解乌鲁鲁对传统原住民阿南古族人的重要意义。这里通过展览、展品和讲座重点讲述朱库尔帕（Tjukurpa，原住民法律、道德体系和文化）以及公园的自然人文历史。持国家公园门票可免费进入中心 —— 所有游客均需持有该门票方可进入乌鲁鲁卡塔丘塔国家公园，可提前预订。从这里驱车仅五分钟即可到达马拉（Mala）停车场，这里是参加由管理员免费引导的马拉步道徒步游（Mala Walk）的集合点。这条步道长达 2 公里，是两条适合家庭游的短途步道之一。另一条步道是库尼亚至慕提朱鲁水潭步道（Kuniya Walk to Mutitjulu Waterhole）。步道围绕着乌鲁鲁巨石的一部分展开，穿过阿南古族人祖先马拉人（马拉意为红褐色兔袋鼠）最早居住的远古洞穴，然后穿过一片传统岩画艺术区，最后抵达坎特峡谷（Kantju Gorge）。要想体验更轻松的旅行方式，可以戴上安全帽，踏上赛格威电动代步车或租一辆自行车，在 11 公里长的自行车道上骑行。",
            },
            {
                "_id": 3,
                "name": "Creatures and crafts",
                "nameCh": "第 3 天： 生物和手工艺品",
                "imagePath": "/img/wululu/w3.jpg",
                "descriptionEn": "After getting acquainted with the local Anangu people, you can go to the Red Desert Reptile Show (Red Desert Reptile Show) at Ayers Rock Resort to learn about other local residents of the Red Center: blue tongue lizard (blue tongue lizard) Bruce, pine Wolverine, a bearded dragon, and Max, a mulga snake. Learn about 73 different reptile species in the desert, then get up close and personal with these critters. Next, attend a stippling workshop at Maruku Arts. You can learn about the various symbols that make up Jukulpa and, with the help of Maruku guides and local artists, create your own work of art to take home. Children over the age of five are welcome to attend.",
                "descriptionCh": "在对当地阿南古族人有一定熟悉后，你可以去艾雅斯岩度假村的红色沙漠爬行动物展（Red Desert Reptile Show）了解红土中心的其他当地居民：蓝舌蜥蜴（blue tongue lizard）Bruce、松狮蜥（bearded dragon）Wolverine 和棕伊澳蛇（mulga snake）Max。了解沙漠中的 73 种不同的爬行动物物种，然后再亲身近距离接触这些小动物们。接下来，参加 Maruku Arts 的点画作坊。你可以了解构成朱库尔帕的各种符号，并在马鲁库导游和当地艺术家的帮助下，自己创作一幅艺术作品带回家。欢迎五岁以上的儿童参加。",
            },
            {
                "_id": 4,
                "name": "Wilderness of Stars and Kata Tjuta",
                "nameCh": "第 4 天： 原野星光展和卡塔丘塔",
                "imagePath": "/img/wululu/w4.jpg",
                "descriptionEn": "Artist Bruce Munro's solar-powered art installation is made up of 50,000 light shafts, and the flickering and swinging lights make Uluru shrouded in interlaced light and shadow, like a blooming flower field. At dawn, when the first rays of the sun rise above the horizon, enjoy the Field of Light exhibit on Sunrise Tours, then sip on freshly brewed Billy tea to reflect on the awe-inspiring sights. Before you leave, learn about your next destination: Kata Tjuta. Nearby Kata Tjuta (meaning 'the bull') may not be as famous as Uluru, but it's just as stunning. These 36 hummocks rise 546 meters above the desert plain. These 36 domes were created in a geological event some 550 million years ago that created not only Uluru, but also the 36 domes. On your return trip, you can take the Walpa Gorge walk through these giant sandstones with SEIT Australian Outback Tours. This trail is shorter at 2.6 kilometers.",
                "descriptionCh": "艺术家布鲁斯 · 蒙罗（Bruce Munro）的太阳能艺术装置由 50,000 个灯轴构成，闪烁摇摆的灯光让乌鲁鲁笼罩在交错的光影中，如同盛放的花田。黎明时分，当第一缕阳光从地平线升起时，欣赏日出之旅的原野星光展（Field of Light），然后喝着现煮的比利茶回味这令人赞叹的光景。在你离开之前，先了解下一个目的地：卡塔丘塔。附近的卡塔丘塔（意思是“多头”）在名气上也许不及乌鲁鲁，但同样令人惊叹。这 36 个圆丘高出沙漠平原 546 米。在大约 5.5 亿年前发生的那次地质事件中，不仅造就了乌鲁鲁，后来紧接着又形成了这 36 个圆丘。回程时你可以跟随 SEIT 澳大利亚内陆之旅走一走瓦帕峡谷步道（Walpa Gorge walk）穿过这些巨型砂岩。这条步道路途较短，行程为 2.6 公里。",
            }
        ]
        },
    ],
    },
    {
        "_id": 9,
        "nameCh": "凯恩斯（昆士兰州）",
        "name": "Cairns",
        "imagePath": "/img/can.jpg",
        "descriptionEn": "Cairns is a coastal city and a local administrative region in northern Queensland, Australia. It was opened in 1876. The city was named after William Wellington Keynes, Governor of Queensland at the time. In the early days, it was a relay supply station for miners going to the Hodgkinson Gold Field (Hodgkinson Gold Field), and later developed into a seaport city that exported sugar, gold, minerals and agricultural products. Today, Cairns is developing rapidly, with a population of 152,729 (2018), and the tourism industry is particularly developed. Because it is very close to many tourist attractions in the state, it has become the first stop for many foreign tourists; it is only an hour and a half by boat to the Great Barrier Reef, Daintree Rainforest and Cape Trials are famous tropical rainforest parks in the area; many tourists Also went to Cooktown, Cape York Peninsula and Atherton Mesa before. Cairns has successfully used the surrounding natural resources to establish many small theme parks for tourists. These include the Jebukai Aboriginal Cultural Park and a 7.5-kilometer sightseeing cable car suspended over the tropical rainforest of the World Heritage Site.",
        "descriptionCh": "凯恩斯（英语：Cairns，又译坚士市、 柬兹、开因兹），是澳大利亚昆士兰州北部的滨海城市和一个地方行政区域，于1876年开埠。该城以当时昆士兰总督威廉·惠灵顿·凯恩斯之名命名。早期是矿工前往霍金森金矿（Hodgkinson Gold Field）的中继补给站，尔后发展成出口蔗糖、黄金、矿物和农产品的海港城市。今日凯恩斯发展迅速，人口152,729（2018年），观光业尤为发达。由于距离州内多处旅游胜地很近，所以成为许多外籍观光客来访的第一站；前往大堡礁搭船仅一个半小时，戴恩树雨林和考验角是当地著名的热带雨林园区；许多游客也经此前去库克敦、约克角半岛和阿瑟顿台地。凯恩斯成功利用周遭的自然资源，为观光客建立了许多小型的主题公园。其中包括哲布凯原住民族文化公园，和一条7.5公里长、悬于世界遗产的热带雨林上的观光缆车。",
        "attractions": [{
            "_id": 1,
            "name": "Experience the Great Barrier Reef",
            "nameCh": "体验大堡礁",
            "imagePath": "/img/kai/ilan.jpg",
            "descriptionEn": "Take a day trip along the Cairns coastline, charter a sailboat, snorkel from hidden beaches or enjoy island life at one of the many eco-resorts in the area. Find out more about Queensland's lesser-known island getaways.",
            "descriptionCh": "你可以在凯恩斯海岸线参加一日游旅程，租一艘帆船出海，在隐秘的海滩浮潜，也可以在该地区众多生态度假村之一享受惬意岛屿生活。了解昆士兰州鲜为人知的岛屿度假胜地的更多信息。",
            "more": []
        },
        {
            "_id": 2,
            "name": "Discover the paradise island",
            "nameCh": "发现天堂般的岛屿",
            "imagePath": "/img/kai/da.jpg",
            "descriptionEn": "The Blue Mountains is an Australian highlight, a vast region stretching out 11,400 kilometres of mountain tops, sandstone cliffs, and lush forest filled valleys. Folk will come across thundering waterfalls, epic lookouts, and even an array of unique wildlife when trekking through the land. Regardless of your choice in adventure, the Blue Mountains is truly the perfect place for an action-packed adventure amidst the Australian natural beauty.",
            "descriptionCh": "蓝山是澳大利亚的一大亮点，广阔的区域绵延 11,400 公里，山顶、砂岩悬崖和茂密的森林山谷。徒步穿越这片土地时，人们会遇到雷鸣般的瀑布、壮观的瞭望台，甚至还有一系列独特的野生动物。无论您选择哪种冒险方式，蓝山都是在澳大利亚自然美景中进行动感十足的冒险的理想场所。",
            "more": []
        },
        {
            "_id": 3,
            "name": "Rafting on the tropical rainforest river",
            "nameCh": "热带雨林河上漂流",
            "imagePath": "/img/kai/piao.jpg",
            "descriptionEn": "Under the canopy of the tropical rainforest, the water is trickling, and boating on it is really the best choice for escaping the world and forgetting worries. Follow Back Country Bliss Adventures, go down the river, arrive at the snorkeling resort, see all kinds of magical marine life underwater, and harvest unforgettable memories.",
            "descriptionCh": "热带雨林树冠下，水流涓涓，泛舟其上，实在是避世忘忧不二之选。跟随 Back Country Bliss Adventures ，沿河顺流而下，抵达浮潜胜地，到水下一窥各种神奇的海洋生物，收获令人难忘的美好回忆。",
            "more": []
        },
        {
            "_id": 4,
            "name": "Picnic on Vlasoff Cay",
            "nameCh": "在瓦拉索沙岛（Vlasoff Cay）上野餐",
            "imagePath": "/img/kai/wa.jpg",
            "descriptionEn": "Where to find a picnic spot as special as Vlasoff Cay? Take a helicopter and land on a small stretch of sand - surrounded by the turquoise Coral Sea. You can snorkel in the crystal clear waters, then enjoy a hearty breakfast and sparkling wine.",
            "descriptionCh": "去哪找像弗拉索夫焦岩（Vlasoff Cay）那么特别的野餐地？乘坐直升机，降落在一小块沙滩上 —— 周围有蓝绿色的珊瑚海（Coral Sea）环绕。你可以在清澈透明的海水中浮潜，然后享受一顿丰盛的早餐和起泡酒。 ",
            "more": []
        },
    ],
    },
    {
        "_id": 10,
        "nameCh": "黄金海岸🔥",
        "name": "Gold Coast🔥",
        "imagePath": "/img/gold.jpg",
        "descriptionEn": "The Gold Coast is a city on the Pacific coast of Queensland, Australia, with a population of 569,997 (2016). Tweed Heads) border. Gold Coast is now the sixth largest city in Australia and an internationally famous tourist city. Its coastal scenery and forest landscape have attracted many new immigrants and tourists from all over the world. It also leads the world in cultural and creative industries, fisheries, urban planning, information construction, racing, water sports and biotechnology.",
        "descriptionCh": "黄金海岸（英语：Gold Coast）是澳大利亚昆士兰州的太平洋沿岸城市，人口为569,997人（2016年），北与府城布里斯本（Brisbane）相邻，南与新南威尔斯州堤维德岬（Tweed Heads）接壤。黄金海岸市如今是澳洲第六大城和国际著名的观光都市，其滨海风光和山林景观吸引了世界各地不少新移民和观光客。其文化创意产业、渔业、都市规划、资讯建设、赛车、水上运动和生物科技方面也在全球引领风骚。",
        "attractions": [
            {
            "_id": 1,
            "name": "Access to famous beaches and surfing meccas",
            "nameCh": "畅享著名的海滩和冲浪圣地",
            "imagePath": "/img/gold/chang.jpg",
            "descriptionEn": "There's a reason it's called the \"Gold Coast\": here you'll find more than 70 kilometers of golden sand, sun, surf and beachside bars. Discover some of the coast's most sun-drenched beaches.",
            "descriptionCh": "人们将其称为“黄金海岸”是有原因的：在这里你可以发现绵延 70 多公里的金色沙滩，沐浴阳光，享受冲浪和海滨酒吧。探寻该海岸洒满阳光的最美海滩。",
            "more": []
        },
        {
            "_id": 2,
            "name": "Experience the Thrills of a Large Theme Park",
            "nameCh": "体验大型主题公园的惊险刺激",
            "imagePath": "/img/gold/park.jpg",
            "descriptionEn": "The Gold Coast has something for all ages to enjoy. You can experience thrilling roller coasters and waterslides here, and watch children cheer when they meet their favorite movie protagonists. Click to find out which Gold Coast park is the best for you to visit.",
            "descriptionCh": "黄金海岸适合所有年龄段游客前来游玩。你可以在这里体验惊险刺激的过山车和滑水道，看到孩子们邂逅自己最爱的电影主角时欢呼雀跃的样子。点击了解哪家黄金海岸公园最适合你去游玩。",
            "more": []
        },
        {
            "_id": 3,
            "name": "Climb to the top of SkyPoint",
            "nameCh": "攀登星空塔观景台（SkyPoint）顶端",
            "imagePath": "/img/gold/xin.jpg",
            "descriptionEn": "On the Gold Coast, you can really \"go to heaven\"! Take the SkyPoint Climb for a must-see view as you climb the facade of the coast's most iconic building. Explore different climbs and book a thrilling experience.",
            "descriptionCh": "在黄金海岸，真的可以“上天”！你可以体验星空塔观景台攀爬（SkyPoint Climb），在攀爬该海岸最具标志性的建筑外墙时，一睹不容错过的风景。探索不同的攀爬活动，并预订惊险刺激的体验活动。  ",
            "more": []
        },
    ],
    },
]