import React, { useState } from 'react'
import { tours } from '../../data/6tours';

function NavBar() {
    const [en, setEn] = useState(true);
    
    function changeLanguage(e){
        if (en === true){
            const element1 = document.querySelectorAll('[id^="en"]');
            // console.log(element1);
            element1.forEach((i)=>{
                i.style.display = 'none';
            })
            const element2 = document.querySelectorAll('[id^="ch"]');
            element2.forEach((i)=>{
                i.style.display = 'block';
            })
        }else{
            const element1 = document.querySelectorAll('[id^="en"]');
            // console.log(element1);
            element1.forEach((i)=>{
                i.style.display = 'block';
            })
            const element2 = document.querySelectorAll('[id^="ch"]');
            element2.forEach((i)=>{
                i.style.display = 'none';
            })
        }
        setEn(!en); 
    }
  return (
    <div className="container-fluid position-relative nav-bar p-0">
        <div className="container-lg position-relative p-0 px-lg-3" style={{"zIndex": "9"}}>
            <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
                <a href="/" className="navbar-brand">
                    <h1 className="m-0 text-primary"><span className="text-dark">FindYour</span>Way</h1>
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                    <div className="navbar-nav ml-auto py-0">
                        <a href="/" className="nav-item nav-link"><p id="en">Home</p><p id="ch">首页</p></a>
                        <a href="/tours" className="nav-item nav-link"><p id="en">City</p><p id="ch">城市</p></a>
                        <a href="/about" className="nav-item nav-link"><p id="en">About</p><p id="ch">关于我们</p></a>
                        <div className="nav-item dropdown">
                            <a id="en" href="/#" className="nav-link dropdown-toggle" data-toggle="dropdown">Tours</a>
                            <a id="ch" href="/#" className="nav-link dropdown-toggle" data-toggle="dropdown">热门线路</a>
                            <div className="dropdown-menu border-0 rounded-0 m-0">
                                {tours.map(a=>{
                                    return (
                                        <a href={`/tours/${a._id}`} className="dropdown-item"><span id="en">{a.name}</span><span id="ch">{a.nameCh}</span></a>
                                    )
                                })}
                            </div>
                        </div>
                        <a href="/contact" className="nav-item nav-link"><p id="en">Contact</p><p id="ch">联系我们</p></a>
                        <a href="#languageChange" onClick={changeLanguage} className="nav-item nav-link"><p id="en">中文</p><p id="ch">English</p></a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
  )
}

export default NavBar