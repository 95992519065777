import React from 'react';
import { 
  BrowserRouter, 
  Route, 
  Routes 
} from "react-router-dom";
import { router } from './router/index';
import NavBar from './components/NavBar/NavBar';
import ToolBar from './components/ToolBar/ToolBar';
import './css/style.css';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <ToolBar/>
      <NavBar/>
      <BrowserRouter>
        <React.Suspense>
          <Routes>
              {router.map((e,i) => (
                <Route path={e.path} element={<e.element/>} key={i} ></Route>
              )
              )}
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <a href="#top" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-angle-double-up"></i></a>
      <Footer/>
    </div>
  );
}

export default App;
