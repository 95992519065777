import React from 'react'

function Footer() {
  return (
    <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{"marginTop": "90px"}}>
        <div className="row pt-5">
            <div className="col-lg-3 col-md-6 mb-5">
                <a href="/" className="navbar-brand">
                    <h1 className="text-primary"><span className="text-white">FindYour</span>Way</h1>
                </a>
                <p>Sed ipsum clita tempor ipsum ipsum amet sit ipsum lorem amet labore rebum lorem ipsum dolor. No sed vero lorem dolor dolor</p>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{"letterSpacing": "5px"}}>Follow Us</h6>
                <div className="d-flex justify-content-start">
                <i className="fa fa-weixin mr-2"></i>
                    {/* <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-tiktok"></i></a> */}
                    {/* <a className="btn btn-outline-primary btn-square mr-2" href="#"></a> */}
                    <i className="fab fa-weibo"></i>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{"letterSpacing": "5px"}}>Our Services</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="/tours"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                    <a className="text-white-50 mb-2" href="/contact"><i className="fa fa-angle-right mr-2"></i>Services</a>
                    <a className="text-white-50 mb-2" href="/about"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{"letterSpacing": "5px"}}>Usefull Links</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="/contact"><i className="fa fa-angle-right mr-2"></i>Terms & Conditions</a>
                    <a className="text-white-50 mb-2" href="/contact"><i className="fa fa-angle-right mr-2"></i>Work For Us</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{"letterSpacing": "5px"}}>Contact Us</h5>
                {/* <p><i className="fa fa-map-marker-alt mr-2"></i>123 Xxxx, Xxxx Xxxx, XXX</p> */}
                {/* <p><i className="fa fa-phone-alt mr-2"></i>+012 345 67890</p> */}
                <p><i className="fa fa-envelope mr-2"></i>visitaus@outlook.com</p>
                <p><i className="fa fa-weixin mr-2"></i>Visitaustralia</p>
            </div>
        </div>
    </div>

  )
}

export default Footer